<template>
  <!-- min-vh-100 -->
  <div class="d-flex content-center content-main-space" :class="baseStyle" style="background-color: white;">
    <BContainer class="container-c">
      <BRow class="justify-content-center">
        <BCol md="10">
          <BCard class="text-left"
            header-bg-variant="howcost"
            header-text-variant="light"
            footer-tag="footer"
            footer-bg-variant="light"
          >
            <div slot="header" style="font-size: medium;">
              <b-icon class="mr-2" icon="person-check-fill" />
              <strong>HowCost 통합 회원가입</strong>
              <div class="card-header-actions ">
                <!-- <small class="text-muted"><span class="text-light">가입하실 계정 정보를 작성해주세요</span></small> -->
              </div>
            </div>

            <BForm @submit="onSubmit">
              <!-- col-md-2 input-group-text -->
              <BInputGroup class="mb-2">
                <b-col class="input-group-text br-0" sm="2">아이디</b-col>
                <!-- <BInputGroupAppend is-text>아이디</BInputGroupAppend> -->
                <BFormInput class="" v-model="newId" name="newId" ref="newId"
                            :state="valideIdState"
                            autocomplete="off"
                            placeholder="아이디 6~20자" :readonly="isIdChecked"
                            maxlength="20" required/>
                <BInputGroupAppend>
                  <BButton variant="info" @click="checkId"
                           :disabled="newId.length<6 || isIdChecked">중복 확인</BButton>
                </BInputGroupAppend>
              </BInputGroup>

              <BInputGroup class="mb-2">
                <b-col class="input-group-text br-0" sm="2">비밀번호</b-col>
                <!-- <BInputGroupAppend is-text>비밀번호</BInputGroupAppend> -->
                <BFormInput v-model="newPwd" autocomplete="off" id="newPwd" name="newPwd" :state="validPwdState"  type="password" placeholder="영문.숫자.특수문자 조합 최소8자" :disabled="!isIdChecked" required/>
                <!-- <BFormInput v-model="newPwdConfirm" autocomplete="off" id="newPwdConfirm" name="newPwdConfirm" :state="validPwdConfirm" type="password" placeholder="비밀번호 확인" :disabled="!isIdChecked" required/> -->
              </BInputGroup>

              <BInputGroup class="mb-4">
                <b-col class="input-group-text br-0" sm="2">비밀번호 확인</b-col>
                <!-- <BInputGroupAppend is-text>비밀번호 재확인</BInputGroupAppend> -->
                <BFormInput v-model="newPwdConfirm" autocomplete="off" id="newPwdConfirm" name="newPwdConfirm" :state="validPwdConfirm" type="password" placeholder="비밀번호 확인" :disabled="!isIdChecked" required/>
              </BInputGroup>

              <BInputGroup  class="mb-1" id="newEmail">
                <b-col class="input-group-text br-0" sm="2">이메일</b-col>
                <!-- <BInputGroupPrepend is-text>이메일</BInputGroupPrepend> -->
                <BFormInput v-model="newEmail" type="email" :state="validateEmail"
                            placeholder="이메일" ref="newEmailInput" :disabled="!isIdChecked || !isPwdChecked" required/>
                <BInputGroupAppend>
                  <BButton variant="warning" @click="sendAuthEmail" autocomplete="off"
                           :disabled="isEmailVerified || !isEmailValid || isEmailSending">
                    <BSpinner small v-show="isEmailSending"></BSpinner>전송
                  </BButton>
                </BInputGroupAppend>
              </BInputGroup>

              <BInputGroup  class="mb-4" id="emailCert">
                <b-col class="input-group-text br-0" sm="2"><BIconUnlockFill/></b-col>
                <!-- <BInputGroupAppend is-text><BIconUnlockFill/></BInputGroupAppend> -->
                <BFormInput v-model="authNo" type="text" maxlength="6"
                            :state="authNo.length===5" autocomplete="off"
                            placeholder="인증번호 입력"
                            :disabled="!isEmailVerified" required/>
              </BInputGroup>

              <BInputGroup  class="mb-2" id="newNick">
                <b-col class="input-group-text br-0" sm="2">* 닉네임</b-col>
                <!-- <BInputGroupPrepend is-text>* 닉네임</BInputGroupPrepend> -->
                <BFormInput v-model="newNick" type="text" placeholder="이름대신 표시할 별명" autocomplete="off" ref="newNickInput" required/>
              </BInputGroup>

              <BInputGroup  class="mb-2" id="newName">
                <b-col class="input-group-text br-0" sm="2">* 이름</b-col>
                <!-- <BInputGroupPrepend is-text>* 이름</BInputGroupPrepend> -->
                <BFormInput v-model="newName" type="text" placeholder="사용자 이름" autocomplete="off" ref="newNameInput" required/>
              </BInputGroup>

              <BInputGroup  class="mb-2" id="newHpNo">
                <b-col class="input-group-text br-0" sm="2">* 연락처</b-col>
                <!-- <BInputGroupPrepend is-text>* 연락처</BInputGroupPrepend> -->
                <BFormInput v-model="newHpNo" type="text" :state="validateHpNo" @keyup="getPhoneMask(newHpNo)"
                            placeholder="휴대폰 번호" autocomplete="hpNo" ref="newHpNoInput" required/>
              </BInputGroup>


              <BInputGroup  class="mb-2">
                <b-col class="input-group-text br-0" sm="2">주 소</b-col>
                <!-- <BInputGroupPrepend is-text>주 소</BInputGroupPrepend> -->
                <BFormInput
                  v-model="newAddr"
                  @click="addressModalShow"
                  placeholder="주소 입력"
                  autocomplete="address"
                />
                <BInputGroupAppend>
                  <BButton variant="info" v-b-modal.addressModal>주소검색</BButton>
                </BInputGroupAppend>
              </BInputGroup>


              <BInputGroup class="mb-3">
                <b-col class="input-group-text br-0" sm="2">상세주소</b-col>
                <!-- <BInputGroupPrepend is-text>상세주소</BInputGroupPrepend> -->
                <BFormInput
                  id="addrDetail"
                  ref="addrDetail"
                  v-model="addrDetail"
                  placeholder="상세주소 입력"
                  autocomplete="address"
                />
              </BInputGroup>

              <BRow class="noto-sans">
                <BCol>

                  <BRow class="py-3 m-0" style="border-bottom: solid 1px rgba(175, 175, 175, 0.641);">
                    <BCol align-self="end" class="text-right">
                      <BFormCheckbox class="mt-1 display-in" id="agreeTerms" v-model="agreeTerms" name="agreeTerms" value="Y" unchecked-value="N">
                        <span class="text-center">이용약관 동의 (필수)</span>
                      </BFormCheckbox>
                      <b-icon class="ml-1 c-pointer text-right" icon="caret-right-fill" v-b-modal.termsModal />
                    </BCol>
                  </BRow>

                  <BRow class="py-3 m-0" style="border-bottom: solid 1px rgba(175, 175, 175, 0.641);">
                    <BCol align-self="end" class="text-right">
                      <BFormCheckbox class="mt-1 display-in" id="agreePolicy" v-model="agreePolicy" name="agreePolicy" value="Y" unchecked-value="N">개인정보 수집 및 이용 동의 (필수)</BFormCheckbox>
                      <b-icon class="ml-1 c-pointer" icon="caret-right-fill" v-b-modal.policyModal />
                    </BCol>
                  </BRow>

                  <BRow class="py-2 mb-3 m-0" style="border-bottom: solid 1px rgba(175, 175, 175, 0.641);">
                    <BCol align-self="end" class="text-right">
                      <p class="mt-2 mb-1" style="font-size: 11pt; font-weight: 500;">이벤트 및 유용한 소식 수신 동의 (선택)</p>
                      <BFormCheckbox  id="agreeEmail" v-model="agreeEmail" value="Y" unchecked-value="N" inline>이메일 수신</BFormCheckbox>
                      <BFormCheckbox class="mr-0" id="agreeSms" v-model="agreeSms" value="Y" unchecked-value="N" inline>SMS 수신</BFormCheckbox>
                      <p class="mt-1 fc-howcost" style="font-weight: 400;">(공지사항, 이벤트 등 도움되는 정보를 받습니다)</p>
                    </BCol>
                  </BRow>
                </BCol>
              </BRow>


              <BRow>
                <BCol class="text-center">
                  <BButtonGroup>
                    <BButton variant="howcost" type="submit" :disabled="isAccountRegistered">
                      <BIconCheckCircleFill/> HowCost 회원 가입
                    </BButton>
                   <!-- <BButton class='ml-1' variant="primary" @click="resetNewId">리셋</BButton> -->
                    <BButton class='ml-1 fc-w' style="width: 80px;" variant="warning" @click="cancelNewId">취소</BButton>
                  </BButtonGroup>
                </BCol>
              </BRow>

            </BForm>


            <BModal id="addressModal" title="우편번호 검색" centered return-focus="addrDetail">
              <DaumPostcode :on-complete="handleAddress"/>
            </BModal>


            <BModal id="termsModal" size="xl" scrollable title="이용약관 동의">
              <BCard class="m-3 p-3" v-html="termsText"/>
              <!--  <BFormTextarea v-model="termsText" rows="100" max-rows="100" disabled/>-->
            </BModal>

            <BModal id="policyModal" size="xl" scrollable title="개인정보 수집 및 이용 동의">
              <BCard class="m-3 p-3" v-html="policyText"/>
              <!--  <BFormTextarea v-model="termsText" rows="100" max-rows="100" disabled/>-->
            </BModal>

            <!-- <div slot="footer">
              <div class="small text-muted">
                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                <span class="hidden-xs en">All rights reserved.</span><br>
                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:mycost@naver.com">mycost@naver.com</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 | fax. 02-6442-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
              </div>
            </div> -->
          </BCard>

        </BCol>
      </BRow>


    </BContainer>

  </div>


</template>

<script>
import {
  apiCall,
  cloneVar,
  alertError,
  alertWarn,
  alertConfirm,
  alertSuccess,
  checkBisNo,
  commify,
  getMask,
  warpSite,
} from '../../../common/utils';
import terms from "./terms/contents.js";
import DaumPostcode from 'vuejs-daum-postcode';
import moment from "moment";



export default {
  name: 'MemberRegister',
  props: ['passValue'],
  components: {
    DaumPostcode
  },
  // props: ['userToken', 'prdCode'],
  data () {
    return {
      // prdCode: '2106-0002',
      order: {},
      compNo: '',
      authNo: '',
      authSeq: '',
      userId: null,
      userPwd: null,
      newId: '',
      newName: '',
      newNick: '',
      newHpNo: '',
      newEmail: '',
      newPwd: '',
      newPwdConfirm: '',
      newAddr: '',
      addrDetail: '',
      addrBuilding: '',
      zipNo: '',
      agreeTerms: 'N',
      agreeEmail : 'N',
      agreeSms : 'N',
      isIdChecked: false,
      isPwdChecked: false,
      isEmailValid: false,
      isEmailChecked: false,
      showTermsBtnClicked: false,
      newAccountBtnShow: true,
      isAccountRegistered: false,

      isEmailVerified: false,
      isUserVerified: false,
      isVerifying: false,
      isDone: false,
      termsText: terms.terms,
      policyText: terms.policy,
      agreePolicy: 'N',
      emailValidCheckText: '이메일 형식입니다 (인증번호 전송버튼을 클릭하세요)',
      taxStatOpts: [
        { value: null, text: '개인/사업자 선택' },
        { value: '03', text: '개인' },
        { value: '01', text: '사업자' },
      ],
      isSubmiting: false,
      isEmailSending:false,
      prdInfo: null,
      imgSrc: null,
      unitPrice: null,
      totalPrice: null,
      orderEnabled: false,
      prdCodeOpts: [
        { text:'1개(1+1)', value:'2106-0002' },
        { text:'1개', value:'2106-0001' },
      ],
      isHpNoValid: false,
      hpNoRegExp: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/, // 휴대폰번호 체크
      // pwdRegexp: /^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{7,29}$/, // 영어+숫자(8자이상)
      pwdRegExp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
      idRegExp: /^[A-Za-z0-9]{6,20}$/, // 영어 대소문자 포함

      baseStyle: '',
    }
  },
  computed: {
    compnoState: function(){
      return checkBisNo(this.compNo);
    },
    valideIdState(){
      return this.idRegExp.test( this.newId )
    },
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.newEmail)) {
        this.isEmailValid = true;
        return true;
      } else {
        this.isEmailValid = false;
        return false;
      }
    },
    validateHpNo(){
      return this.hpNoRegExp.test(this.newHpNo);
    },
    validPwdState(){
      let ret = false;
      // const p2 = this.newPwdConfirm;
      if( this.newPwd.length > 7 ){
        if( this.pwdRegExp.test( this.newPwd )) ret = true;
      }
      return ret;
    },

    validPwdConfirm(){
      let ret = false;
      const p1 = this.newPwd;
      const p2 = this.newPwdConfirm;
      if( p2.length > 7 && p1 === p2 && this.pwdRegExp.test( this.newPwdConfirm ) ){
        ret = true;
        this.isPwdChecked = true;
      }else{
        this.isPwdChecked = false;
      }
      return ret;
    },
  },

  async created(){

    // console.log("-------- created:  ----- passValue:", this.passValue );

    if (this.$route.path === '/member-register') {
      this.baseStyle = 'min-vh-100';
    }

    try{

    }catch(err){
      console.log( err  );

    }
  },
  beforeMount() { },

  mounted(){
    // console.log("-------- mounted: MemberRegister -----");
    if(this.userToken){
      this.getUserInfo();
    }
  },

  methods: {
    async getPhoneMask(phoneNumber) {
        let res = getMask(phoneNumber);
        this.newHpNo = res;
    },

    addressModalShow(){
      this.$bvModal.show("addressModal");
    },

    showTermsToggle(){
      this.showTermsBtnClicked = !this.showTermsBtnClicked;
    },

    async checkId(){
      // console.log( "checkUserId ---- " + this.newId );
      try{
        // const regText = /^[a-zA-Z](?=.{0,20}[0-9])[0-9a-zA-Z]{6,20}$/;
        if (!this.idRegExp.test(this.newId)) {
          await alertWarn(this.$bvModal, '아이디는 영문을 반드시 포함하고 6~20자를 사용하세요');
          return;
        }
        let userId = this.newId.trim();

        const r = await apiCall('get', `/api/member/check-id/${userId}`);
        // console.log( "r.result.cnt -------------->", r.result.cnt );

        if(r.result.cnt===0){
          await alertSuccess(this.$bvModal, "사용가능한 아이디입니다.", "Success");
          this.isIdChecked = true;
        }else{
          this.isIdChecked = false;
          await alertWarn(this.$bvModal, `${this.newId} 아이디는 사용 할 수 없습니다.`);
          return;
        }
      }catch(err){
        console.log( err );
        await alertError(this.$bvModal, `에러: `+err.message);
      }
    },


    async createNewId(){


    },

    resetNewId(){
      this.newId = '';
      this.newEmail = '';
      this.newNick = '';
      this.newName = '';
      this.newPwd = '';
      this.newPwdConfirm = '';

      this.isIdChecked = false;
      this.isPwdChecked = false;
      this.isEmailChecked = false;
      this.isAccountRegistered = false;
      this.$refs['newId'].focus();
    },

    async cancelNewId(){
      try{
        this.resetNewId();
        this.showTermsBtnClicked = false;
        await this.movePage();
      }catch(err){
        console.log(err);
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    handleAddress(data) {
      // console.log( "handleAddress -------------> ", data );
      let fullAddress = data.address;
      let extraAddress = '';
      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname
        }
        if (data.buildingName !== '') {
          extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName)
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '')
      }
      // console.log(fullAddress);

      this.newAddr = data.roadAddress;
      this.zipNo = data.zonecode;
      this.addrBuilding = `(${data.bname}, ${data.buildingName})`;
      this.$bvModal.hide("addressModal");
      this.$nextTick(
          ()=>{
            this.$refs.addrDetail.focus();
          }
      );

    },

    async onSubmit(evt){
      evt.preventDefault();
      // console.log("newAddr ======>", this.newAddr);
      // console.log("addrDetail ======>", this.addrDetail);
      console.log( "-------onSubmit---------" );
      try{
        if(!this.authNo){
          await alertWarn(this.$bvModal, `인증번호를 입력 하세요`);
          return;
        }

        if(!this.hpNoRegExp.test(this.newHpNo)){
          await alertWarn(this.$bvModal, `연락처를 확인해 주세요`);
          return;
        }
        // console.log( "onsubmit --------- agreeTerms:", this.agreeTerms );
        // console.log( "onsubmit --------- agreePolicy:", this.agreePolicy );

        if(this.agreeTerms!=='Y'){
          await alertWarn(this.$bvModal, `회원가입 약관 동의가 필요합니다.`);
          return;
        }

        if(this.agreePolicy!=='Y'){
          await alertWarn(this.$bvModal, `개인정보 처리방침 동의가 필요합니다.`);
          return;
        }

        const userInfo = {
          userId: this.newId, //계정ID
          userPwd: this.newPwd, //비밀번호
          nick: this.newNick, //닉네임
          name: this.newName, //이름
          email: this.newEmail, //이메일
          hpNo : this.newHpNo, //연락처
          addr: this.newAddr, //주소
          addrDtl: this.addrDetail, //상세주소
          addr3: this.addrBuilding, //([구]동네이름, 빌딩이름)
          zipNo: this.zipNo, //우편번호
          isMailing: this.agreeEmail==='Y'? true:false,
          isSms: this.agreeSms==='Y'? true:false,
          emailAuthSeq: this.authSeq,
          emailAuthNo: this.authNo
        };

        // console.log("가입할 계정정보: ", userInfo);

        const r = await apiCall('post', `/api/member/register`, userInfo );
        // console.log(r.result);
        const xcUser = r.result;
        if(xcUser._id){
          this.isAccountRegistered = true;
          this.isUserVerified = false;
          this.userId = xcUser.userId;
          this.newEmail = xcUser.email;
          this.newHpNo = xcUser.hpNo;
          this.newName = xcUser.name;
          await alertSuccess(this.$bvModal, `${this.newName} 사용자의 아이디(${xcUser.userId})가 등록 되었습니다.`);

          await this.movePage();

        }else{
          await alertWarn(this.$bvModal, '사용자 등록 실패');
          console.error( r.message )
          // setTimeout(()=>{
          //   window.location.href = "http://xcost.me"
          // }, 2000);

        }
      }catch(err){
        await alertError(this.$bvModal, '사용자 등록 오류: '+err.message);
        console.log(err);
      }
    },
    async movePage(){
      try{
        if(this.passValue && this.passValue.fromView){
          const routeTo = this.passValue.fromView
          if( routeTo==='XcostPremiumSubscription' ){
            await this.$router.push({name:routeTo, params: { prdCode: this.passValue.prdCode, userId: this.userId }});
          }else if( routeTo==='MultiOrder'){
            await this.$router.push({name:routeTo, params: { salesCode: this.passValue.salesCode, newUserId: this.userId }});
          }
          else{
            await this.$router.push({name:routeTo, params: {} });
          }
        }else{
          setTimeout(()=>{
            // window.location.href = "http://xcost.me"
            window.location.href = warpSite();
          }, 500);
        }
      }catch(err){
        console.log('movePate', err)
      }

    },

    resetForm() {
      this.resetNewId();
      this.isUserVerified = false;
      this.isEmailVerified = false;
      this.isEmailValid = false;
      this.userId = '';
      this.userPwd = '';
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

    async sendAuthEmail(){
      try{

        if(!await alertConfirm(this.$bvModal, `${this.newEmail}로 인증번호를 전송합니다.`, '이메일 전송' )){
          return;
        }
        //console.log("sendAuthEmail ------------- order:", this.order);
        this.isEmailSending = true;
        const r = await apiCall('post', '/api/order/send-auth-email', {email: this.newEmail});
        // console.log( "sendAuthEmail---------------->", r );
        if( r.code===200 ) {
          this.isEmailVerified = true;
          this.emailValidCheckText = '이메일을 전송하였습니다. 인증번호를 입력하세요';
          this.authSeq = r.result.auth_seq;

        }else{
          await alertError(this.$bvModal, "이메일 전송실패", r.code);
          this.isemailSending = false;
        }
      }catch(err){
        this.isEmailSending = false;
        console.log(err);
      }finally{
        this.isEmailSending = false;
      }

    },


  },

}
</script>